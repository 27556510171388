import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Slider from 'react-slick';
import LeftArrowIcon from '../svg/left.svg';
import RightArrowIcon from '../svg/right.svg';
import ClientBadge from '../components/client-badge';
import moment from 'moment';
import '../scss/project-detail.scss';

function Arrow({className, style, onClick}) {
  return (
    <button
      type="button"
      className={`outline-none focus:outline-none ${className}`}
      onClick={onClick}
    > 
      {className.indexOf('slick-next') > -1 && <RightArrowIcon className="slick-arrow-icon" />}
      {className.indexOf('slick-next') === -1 && <LeftArrowIcon className="slick-arrow-icon" />}
    </button>
  );
}

export const query = graphql`
  query ($slug: String!) {
    projectsJson(slug: {eq: $slug}) {
      client
      description
      date
      slug
      title
      carousel {
        type
        aspect
        url
      }
    }
  }
`
export default ({ data }) => {
  const project = data.projectsJson;
  const {title, client, description, date, carousel} = project;

  const renderCarouselSlide = ({url, type, aspect}, index) => {
    return (
      <Fragment key={index}>
        {(() => {
          switch(type) {
            case 'youtube': case 'vimeo':
              const [aspectW, aspectH] = aspect.split('by');
              return (
                <div style={{paddingTop: `${( parseFloat(aspectH) / parseFloat(aspectW) ) *100}%`}} className="relative w-full">
                  <iframe title={title} className="absolute left-0 top-0 w-full h-full" src={url} allowFullScreen="" />
                </div>
              );
            case 'image':
              return (
                <img className="w-full" src={url} alt={`${title} - ${index}`} />
              )
          
            default:
              return null;
          }
        })()}
      </Fragment>
    )
  }

  return (
    <Fragment>
      <SEO title={title} />
      <div className="container mt-20">
        <Slider
          autoplay
          accessibility
          arrows
          className="project-carousel inner"
          dots
          dotsClass="slick-dots"
          fade={false}
          lazyLoad={false}
          pauseOnHover
          speed={750}
          adaptiveHeight={false}
          prevArrow={<Arrow />}
          nextArrow={<Arrow />}
        >
          {carousel.map(renderCarouselSlide)}
        </Slider>
        <div className="project-info inner">
          <h1 className="project-title">{title}</h1>
          <ClientBadge name={client} />
          <div className="project-date">{moment(new Date(date)).format("M[/]D[/]YYYY")}</div>
          <div className="project-description" dangerouslySetInnerHTML={{ __html:description }} />
        </div>	
      </div>
    </Fragment>
  )
}
